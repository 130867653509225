import React from 'react'

function FunnelIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="40"
      height="31"
      viewBox="0 0 40 31"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5932 21.2188H16.1055V30.919L24.5932 26.6752V21.2188ZM18.5307 28.13L17.3182 28.9788L17.3179 22.4312H18.5304L18.5307 28.13Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.98047 4.84961L16.1058 20.0059H24.5935L36.7188 4.84961H3.98047ZM18.5308 17.5808H17.197L9.31589 7.27468H18.531L18.5308 17.5808Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.949219 -0.000976562H39.7495V3.63662H0.949219L0.949219 -0.000976562Z"
      />
    </svg>
  )
}

export default FunnelIcon
