import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { FunnelFilterInterface } from 'modules/funnels/types/funnel-filter-interface'
import { FunnelApiInterface } from 'modules/funnels/types/funnel-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { CountInterface, SwrPaginationInterface } from 'shared/types/pagination-interface'
import { baseFetcher } from 'shared/utils/http-client'
import useSWR from 'swr'

interface UseFunnelsInterface extends SwrPaginationInterface {
  filter: FunnelFilterInterface
}

export function useFunnels({ limit, pagination, filter }: UseFunnelsInterface) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, FunnelApiInterface>({
    method: RequestMethodsEnum.get,
  })

  const filterApi = `${
    filter.search !== '' ? `&filter[searchBy]=${encodeURIComponent(filter.search)}` : ''
  }${filter.status !== undefined ? `&filter[isActive]=${filter.status}` : ''}`

  const { data: funnels, mutate } = useSWR(() => {
    if (user && pagination.startFromId === undefined && limit) {
      return `${FUNNELS_API}/list?pagination[limit]=${limit}${filterApi}`
    } else if (user && pagination.startFromId && limit) {
      return `${FUNNELS_API}/list?pagination[startFrom]=${pagination.startFromId}&pagination[order]=${pagination.order}&pagination[limit]=${limit}${filterApi}`
    }
    return null
  }, fetcher)

  const { data: counter } = useSWR<CountInterface>(() => {
    if (user) {
      return `${FUNNELS_API}/count?pagination[limit]${filterApi}`
    }
    return null
  }, baseFetcher)

  return { funnels, mutate, counter }
}
