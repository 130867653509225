import { PAYMENT_SETTINGS_API } from 'modules/profile/payment/api/payment-settings-api'
import { UsePaymentSettingsInterface } from 'modules/profile/payment/types/payment-settings-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function usePaymentSettings(shouldFetch: boolean) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    UsePaymentSettingsInterface
  >({ method: RequestMethodsEnum.get })

  const { data: paymentSettingsData, mutate } = useSWR(() => {
    if (user && shouldFetch) {
      return PAYMENT_SETTINGS_API
    }
    return null
  }, fetcher)

  return { paymentSettingsData, mutate }
}
