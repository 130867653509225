import {
  FunnelTypeDescriptionEnum,
  FunnelTypeTitleEnum,
} from 'modules/funnels/enums/funnel-type-enum'
import { FunnelCreateInterface } from 'modules/funnels/types/funnel-interface'
import React from 'react'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from 'shared/components/form/field-error-and-description'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import FunnelIcon from 'shared/icons/funnel-icon'
import SocialCommunityIcon from 'shared/icons/social-community-icon'
import StoreIcon from 'shared/icons/store-icon'
import SupportIcon from 'shared/icons/support-icon'

interface FunnelGoalSelectorProps extends FieldErrorAndDescriptionProps {
  value: FunnelCreateInterface['type']
  onChange: (value: FunnelCreateInterface['type']) => void
}

const FunnelGoalSelector = ({ value, onChange, error }: FunnelGoalSelectorProps) => {
  const { t } = useLocoTranslation()
  return (
    <div className={`flex flex-col gap-1`} data-test="funnel-goal-selector">
      <div className="flex gap-0.5">
        <span>{t('dashboard.funnel.choose_funnel_goal')}</span>
        <span className="text-danger">*</span>
      </div>
      <ul className={`flex gap-3 flex-wrap flex-col lg:flex-row`}>
        {Object.keys(FunnelTypeTitleEnum).map(el => {
          const key = el as keyof typeof FunnelTypeTitleEnum
          const isSelected = value === key
          return (
            <li key={key}>
              <button
                onClick={e => {
                  e.preventDefault()
                  onChange(key)
                }}
                className={`w-full lg:w-[290px] h-full items-start rounded-lg border ${
                  isSelected ? 'border-blue' : 'border-gray/50'
                } px-4 py-4 flex flex-col gap-1 outline-none focus-visible:border-blue main-transition-colors hover:border-blue`}
                data-test={`funnel-goal-${key}`}
              >
                {key === 'optin' && (
                  <SocialCommunityIcon
                    className={`${isSelected && 'fill-blue'} main-transition-colors h-[30px]`}
                  />
                )}
                {key === 'sales' && (
                  <StoreIcon
                    className={`${isSelected && 'fill-blue main-transition-colors'} h-[30px]`}
                  />
                )}
                {key === 'custom' && (
                  <FunnelIcon
                    className={`${isSelected && 'fill-blue main-transition-colors'} h-[30px]`}
                  />
                )}
                {key === 'webinar' && (
                  <SupportIcon
                    className={`${isSelected && 'fill-blue main-transition-colors'} h-[30px]`}
                  />
                )}
                <span className={'text-start text-darkblue font-bold'}>
                  {t(FunnelTypeTitleEnum[key])}
                </span>
                <span className={'text-start'}>{t(FunnelTypeDescriptionEnum[key])}</span>
              </button>
            </li>
          )
        })}
      </ul>
      <FieldErrorAndDescription error={error} />
    </div>
  )
}

export default FunnelGoalSelector
