import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { FunnelInterface } from 'modules/funnels/funnel/types/funnel-interface'
import { FunnelId } from 'modules/funnels/types/funnel-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export function useFunnel(id?: FunnelId) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, FunnelInterface>({
    method: RequestMethodsEnum.get,
  })

  const { data: funnel, mutate: mutateFunnel } = useSWR(() => {
    if (user && id) {
      return `${FUNNELS_API}/${id}`
    }
    return null
  }, fetcher)

  return { funnel, mutateFunnel }
}
