import React from 'react'

function SupportIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 50 40"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.8398 18.3711C32.8398 25.5586 27.0133 31.3847 19.8261 31.3847C12.6386 31.3847 6.8125 25.5583 6.8125 18.3711C6.8125 11.1839 12.6389 5.35742 19.8261 5.35742C27.0133 5.35742 32.8398 11.1839 32.8398 18.3711Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.86618 16.7521C5.03597 8.64575 11.6785 2.10082 19.8261 2.10082C27.9425 2.10082 34.5652 8.5952 34.7837 16.6595C34.7506 16.8242 34.7312 24.1857 34.7312 24.1857C34.7312 24.4961 34.7871 24.7923 34.8858 25.0626C33.3255 30.5284 28.7347 34.7225 23.0533 35.7069C22.5339 35.268 21.779 34.9901 20.9365 34.9901C19.3709 34.9901 18.1013 35.946 18.1013 37.1248C18.1013 38.3052 19.371 39.2607 20.9365 39.2607C22.2228 39.2607 23.3072 38.6162 23.6547 37.7326C29.7192 36.592 34.659 32.2346 36.6325 26.5058C36.6951 26.5125 36.7561 26.5168 36.8206 26.5168C37.9748 26.5168 38.9116 25.4741 38.9116 24.1856L38.9112 17.1727C38.9112 15.8846 37.9751 14.8409 36.8203 14.8409C36.7944 14.8409 36.7698 14.8442 36.7449 14.8448C35.6512 6.48194 28.4831 0 19.8263 0C11.1686 0 4.00087 6.48155 2.90758 14.8464C2.87934 14.8452 2.85139 14.8412 2.82192 14.8412C1.67288 14.8412 0.742188 15.8849 0.742188 17.1731V24.1859C0.742188 25.4744 1.67288 26.5171 2.82192 26.5171C3.97158 26.5171 4.90289 25.4744 4.90289 24.1859"
      />
    </svg>
  )
}

export default SupportIcon
