import { BLOGS_API } from 'modules/blogs/api/blog-api'
import { CAMPAIGNS_API } from 'modules/campaigns/api/constants'
import { COURSES_API } from 'modules/courses/courses/api/courses-api'
import { FUNNELS_API } from 'modules/funnels/api/funnels-api'
import { NEWSLETTERS_API } from 'modules/newsletters/api/newsletters-api'
import {
  ResourceType,
  ShareResourceApiInterface,
} from 'modules/share/types/share-interface'
import { useEffect, useState } from 'react'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

type UseShareUrlType = {
  opened: boolean
} & ResourceType

export const useShareUrl = ({
  blogId,
  newsletterId,
  campaignId,
  courseId,
  funnelId,
  opened,
}: UseShareUrlType) => {
  const [shouldFetch, setShouldFetch] = useState(false)
  useEffect(() => {
    if (opened) setShouldFetch(true)
  }, [opened])
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    ShareResourceApiInterface
  >({
    method: RequestMethodsEnum.get,
  })

  return useSWR(() => {
    if (!user || !shouldFetch) return
    if (blogId) return `${BLOGS_API}/${blogId}/share-url`
    if (newsletterId) return `${NEWSLETTERS_API}/${newsletterId}/share-url`
    if (campaignId) return `${CAMPAIGNS_API}/${campaignId}/share-url`
    if (courseId) return `${COURSES_API}/${courseId}/share-url`
    if (funnelId) return `${FUNNELS_API}/${funnelId}/share-url`
  }, fetcher)
}
