import { duplicateFunnel } from 'modules/funnels/api/funnels-api'
import {
  FunnelDuplicateInterface,
  FunnelInterface,
} from 'modules/funnels/types/funnel-interface'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import FormInput from 'shared/components/form-input'
import { FieldErrorAndDescription } from 'shared/components/form/field-error-and-description'
import Modal from 'shared/components/modal'
import { BadRequest } from 'shared/errors/bad-request'
import { InternalError } from 'shared/errors/internal-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { fetchWithErrors } from 'shared/utils/fetch-with-errors'

interface FunnelDuplicateModalProps {
  opened: boolean
  onClose: () => void
  funnel: FunnelInterface
  onUpdateData: () => Promise<void>
}

const defaultErrors = {
  name: '',
  common: '',
}

const FunnelDuplicateModal = ({
  opened,
  onClose,
  funnel,
  onUpdateData,
}: FunnelDuplicateModalProps) => {
  const { t } = useLocoTranslation()
  const [name, setName] = useState(`${funnel.name} (copy)`)
  const [isFetching, setIsFetching] = useState(false)
  const [errors, setErrors] = useState(defaultErrors)

  const onDuplicateFunnel = async (funnel: FunnelInterface, data: FunnelDuplicateInterface) => {
    try {
      setIsFetching(true)
      await fetchWithErrors(async () => {
        await duplicateFunnel(funnel.id, data)
        await onUpdateData()
        toast.success(t('global.duplicated'))
        setIsFetching(false)
      }, setErrors)
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        if (e.errors.common) {
          const commonError = e.errors.common.join('')
          setErrors(prev => ({ ...prev, common: commonError }))
        }
      } else if (e instanceof InternalError) {
        setErrors(t('core.error.internal_error_message'))
      } else {
        toast.error(t('global.error'))
      }
    }
  }
  return (
    <Modal
      isFetching={isFetching}
      opened={opened}
      onClose={onClose}
      afterLeave={() => {
        setName(`${funnel.name} (copy)`)
        setErrors(defaultErrors)
      }}
      title={t('dashboard.funnel.duplicate_funnel')}
    >
      <form className="flex flex-col gap-6 lg:gap-10">
        <div className="flex justify-center flex-col gap-3">
          <FormInput
            value={name}
            label={t('dashboard.funnel.name_of_duplicate_funnel')}
            onChange={e => setName(e.target.value)}
            error={errors.name}
          />
        </div>
        <div className="flex justify-center gap-4">
          <PrimaryButton
            isFetching={isFetching}
            disabled={name === ''}
            onClick={async e => {
              e.preventDefault()
              await onDuplicateFunnel(funnel, { name })
            }}
          >
            {t('global.duplicate')}
          </PrimaryButton>
        </div>
        {errors.common && (
          <FieldErrorAndDescription error={errors.common} errorClassName={'text-center'} />
        )}
      </form>
    </Modal>
  )
}

export default FunnelDuplicateModal
