export enum FunnelTypeTitleEnum {
  optin = 'dashboard.funnel.optin_title',
  sales = 'dashboard.funnel.sale_title',
  custom = 'dashboard.funnel.custom_title',
  webinar = 'dashboard.funnel.webinar_title',
}

export enum FunnelTypeDescriptionEnum {
  optin = 'dashboard.funnel.optin_description',
  sales = 'dashboard.funnel.sale_description',
  custom = 'dashboard.funnel.custom_description',
  webinar = 'dashboard.funnel.webinar_description',
}
