import {
  BlogCategoriesCreateInterface,
  BlogCategoriesEditInterface,
  BlogCategoriesInterface,
} from 'modules/blogs/types/blog-categories-interface'
import {
  BlogCreateInterface,
  BlogEditInterface,
  BlogInterface,
} from 'modules/blogs/types/blog-interface'
import {
  BlogPagesEditInterface,
  BlogPagesInterface,
} from 'modules/blogs/types/blog-pages-interface'
import {
  BlogPostsEditInterface,
  BlogPostsInterface,
} from 'modules/blogs/types/blog-posts-interface'
import httpClient from 'shared/utils/http-client'
import { BlogEntityDuplicateInterface } from '../types/blog-entity-interface'

export const BLOGS_API = '/api/dashboard/customer/blogs'

export const duplicateBlog = (id: number, data: BlogEntityDuplicateInterface) =>
  httpClient.post<BlogInterface>(`${BLOGS_API}/duplicate/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const removeBlog = (id: number) => httpClient.delete(`${BLOGS_API}/${id}`)

export const updateBlog = (id: number, data: BlogEditInterface) =>
  httpClient.patch<BlogInterface>(`${BLOGS_API}/${id}`, data)

export const createBlog = (data: BlogCreateInterface) =>
  httpClient.post<BlogInterface>(`${BLOGS_API}`, data)

export const createPage = (id: number, data: BlogPagesEditInterface) =>
  httpClient.post(`${BLOGS_API}/${id}/pages`, data)

export const updatePage = (
  id: number,
  data: BlogPagesEditInterface | Pick<BlogPagesEditInterface, 'name'>,
) => httpClient.put<BlogPagesInterface>(`${BLOGS_API}/pages/${id}`, data)

export const deletePage = (id: number) => httpClient.delete(`${BLOGS_API}/pages/${id}`)

export const duplicatePage = (id: number, data: BlogEntityDuplicateInterface) =>
  httpClient.post(`${BLOGS_API}/pages/duplicate/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const changePageTemplate = (id: number) =>
  httpClient.put(`${BLOGS_API}/pages/${id}/change-template`)

export const createPost = (id: number, data: BlogPostsEditInterface) =>
  httpClient.post(`${BLOGS_API}/${id}/posts`, data)

export const deletePost = (id: number) => httpClient.delete(`${BLOGS_API}/posts/${id}`)

export const updatePost = (id: number, data: BlogPostsEditInterface) =>
  httpClient.put<BlogPostsInterface>(`${BLOGS_API}/posts/${id}`, data)

export const duplicatePost = (id: number, data: BlogEntityDuplicateInterface) =>
  httpClient.post(`${BLOGS_API}/posts/${id}/duplicate`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const createCategory = (id: number, data: BlogCategoriesCreateInterface) =>
  httpClient.post(`${BLOGS_API}/${id}/category`, data)

export const updateCategory = (id: number, data: BlogCategoriesEditInterface) =>
  httpClient.put<BlogCategoriesInterface>(`${BLOGS_API}/category/${id}`, data)

export const deleteCategory = (id: number) => httpClient.delete(`${BLOGS_API}/category/${id}`)
