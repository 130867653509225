import {
  NewsletterCreateApiInterface,
  NewsletterId,
  NewsletterInterface,
} from 'modules/newsletters/types/newsletter-interface'
import httpClient from 'shared/utils/http-client'

export const NEWSLETTERS_API = '/api/dashboard/customer/mailing/newsletters'

export const removeNewsletter = (id: NewsletterId) => httpClient.delete(`${NEWSLETTERS_API}/${id}`)

export const createNewsletter = (data: NewsletterCreateApiInterface) =>
  httpClient.post<NewsletterInterface>(NEWSLETTERS_API, data)

export const duplicateNewsletter = (id: NewsletterId) =>
  httpClient.post<NewsletterInterface>(`${NEWSLETTERS_API}/duplicate/${id}`)

export const resendNewsletter = (id: NewsletterId, data: any) =>
  httpClient.post<NewsletterInterface>(`${NEWSLETTERS_API}/resend/${id}`, data)

export const shareNewsletter = (id: NewsletterId) =>
  httpClient.post<string>(`${NEWSLETTERS_API}/share/${id}`)

export const getDefaultNewsletterFields = () =>
  httpClient.get<{ senderAddress: string; senderName: string }>(
    `${NEWSLETTERS_API}/mailing-settings`,
  )
