import { useShareUrl } from 'modules/share/hooks/use-share-url'
import { ResourceType } from 'modules/share/types/share-interface'
import React, { FC, useEffect } from 'react'
import LinkShareModal, { LinkShareModalProps } from 'shared/components/link-share-modal'

type ShareUrlModalProps = Pick<LinkShareModalProps, 'opened' | 'onClose' | 'label' | 'title'> &
  ResourceType
const ShareUrlModal: FC<ShareUrlModalProps> = ({ onClose, opened, label, title, ...resource }) => {
  const {
    data: resourceData,
    isValidating,
    error,
  } = useShareUrl({
    ...resource,
    opened,
  })

  useEffect(() => {
    if (error && opened) {
      onClose()
    }
  }, [error, onClose, opened])

  const shareUrl = resourceData?.shareUrl || ''

  return (
    <LinkShareModal
      opened={opened}
      link={shareUrl}
      title={title}
      label={label}
      onClose={onClose}
      isLoading={isValidating}
    />
  )
}

export default ShareUrlModal
