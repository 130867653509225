import React from 'react'

function SocialCommunityIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="45"
      height="26"
      viewBox="0 0 45 26"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2636 10.1633C40.2636 12.6577 38.2417 14.6796 35.7472 14.6796C33.2528 14.6796 31.2305 12.6577 31.2305 10.1633C31.2305 7.66881 33.2528 5.64648 35.7472 5.64648C38.2417 5.64648 40.2636 7.66882 40.2636 10.1633Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.2136 25.4062C44.21 22.5006 42.7174 19.7998 40.2588 18.2508C37.8005 16.7019 34.7197 16.5215 32.0977 17.773C33.3721 19.7024 34.0514 21.9641 34.051 24.2771V25.4061L44.2136 25.4062Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9863 10.1633C12.9863 12.6577 10.9639 14.6796 8.46948 14.6796C5.97503 14.6796 3.95312 12.6577 3.95312 10.1633C3.95312 7.66881 5.97503 5.64648 8.46948 5.64648C10.9639 5.64648 12.9863 7.66882 12.9863 10.1633Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00401625 25.4062H10.1665V24.2771C10.1661 21.9642 10.8455 19.7024 12.1199 17.7731C9.49777 16.5215 6.41697 16.7019 3.95872 18.2508C1.50006 19.7996 0.00753625 22.5004 0.00390625 25.4062H0.00401625Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3197 6.21022C28.3197 9.64037 25.5389 12.4208 22.1091 12.4208C18.6792 12.4208 15.8984 9.64046 15.8984 6.21022C15.8984 2.78036 18.6792 0 22.1091 0C25.5389 0 28.3197 2.78036 28.3197 6.21022Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2388 14.6787H20.9806C18.435 14.6787 15.9936 15.69 14.1939 17.4902C12.3938 19.29 11.3828 21.7313 11.3828 24.2769V25.406H32.837V24.2769C32.837 21.7313 31.826 19.2899 30.0258 17.4902C28.226 15.6901 25.7848 14.6787 23.2392 14.6787H23.2388Z"
      />
    </svg>
  )
}

export default SocialCommunityIcon
